import ClimateRiskRatingService from '@/services/climate-risk-rating-service'
import _ from 'lodash'
export default {
  namespaced: true,
  state: {
    climateRiskRatings: [],
    globalClimateRiskRatings: {}
  },

  getters: {
    getClimateRiskRatings(state) {
      return state.climateRiskRatings
    }
  },

  mutations: {
    SET_CLIMATE_RISK_RATINGS(state, data) {
      state.climateRiskRatings = data
    },
    SET_GLOBAL_CLIMATE_RISK_RATINGS(state, data) {
      state.globalClimateRiskRatings = data
    },
  },

  actions: {
    // action used to get all the climate risk ratings
    async getClimateRiskRatings({ commit }) {
      try {
        const res = await ClimateRiskRatingService.findAll()
        commit("SET_CLIMATE_RISK_RATINGS", res)
        return res
      } catch (e) {
        throw e
      }
    },
    // action used to get all the global climate risk ratings
    async getGlobalClimateRiskRatings({ commit }) {
      try {
        const res = await ClimateRiskRatingService.findAllGlobal()
        commit("SET_GLOBAL_CLIMATE_RISK_RATINGS", res)
        return res
      } catch (e) {
        throw e
      }
    },
    // action used to update a climate risk rating
    async updateClimateRiskRating(_, obj) {
      try {
        const res = await ClimateRiskRatingService.update(obj)
        return res
      } catch (e) {
        throw e
      }
    },
    // action used to update global climate risk rating
    async updateGlobalClimateRiskRating(_, obj) {
      try {
        const res = await ClimateRiskRatingService.updateGlobal(obj)
        return res
      } catch (e) {
        throw e
      }
    },
  }
}