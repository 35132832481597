
import loanOrginationSettingsRoutes from './loan-origination-settings-route'
import transactionMonitoringSettingsRoutes from './transaction-monitoring-settings-route'
export default {
  path: 'app-settings',
  name: 'app-settings',
  component: () => import(/* webpackChunkName: "login" */ '@/layouts/App-Settings-Layout.vue'),
  redirect: {
    name: "loan-origination-settings"
  },
  children: [
    loanOrginationSettingsRoutes,
    transactionMonitoringSettingsRoutes    
  ]
}
