import httpClient from '@/common/http'

export default {
  create(obj) {
    return httpClient.request('post', '/settings/loan_origination/loan_approvals/loan-approval-policy', obj);
  },

  update(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_approvals/loan-approval-policy`, obj);
  },

  updateAmount(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_approvals/loan-approval-amount`, obj);
  },

  updateLevelActive(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_approvals/approval-level-active`, obj);
  },

  updateLoanTypeActive(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_approvals/loan-type-active`, obj);
  },

  findOne(level) {
    return httpClient.request('get', `/settings/loan_origination/loan_approvals/loan-approval-policy?level=${level}`)
  },

  findAll() {
    return httpClient.request('get', '/settings/loan_origination/loan_approvals/loan-approval-policies')
  },

  delete(id) {
    return httpClient.request('delete', `/settings/loan_origination/loan_approvals/loan-approval-policy/${id}`);
  }
}