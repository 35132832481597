import httpClient from '@/common/http'

export default {
  addApps() {
    return httpClient.request('get', 'release-notes/apps')
  },

  createVersion(obj) {
    return httpClient.request('post', 'release-notes/add', obj);
  },

  editNotes(obj) {
    return httpClient.request('put', `release-notes/edit`, obj);
  },

  editVersion(obj) {
    return httpClient.request('put', `release-notes/edit-version`, obj);
  },

  findVersions(obj) {
    return httpClient.request('post', 'release-notes/get', obj)
  },

  findLatest(){
    return httpClient.request('get', 'release-notes/latest')
  },

  deleteVersion(id) {
    return httpClient.request('delete', `release-notes/delete`, {id:id});
  }

}