import httpClient from '@/common/http'

export default {
  batchSave(users) {
    return httpClient.request('post', 'users/batch', users);
  },

  create(obj) {
    return httpClient.request('post', 'users', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `users/${id}`, obj);
  },

  delete(id) {
    return httpClient.request('delete', `users/${id}`);
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `users?${filter}${query}`)
  },

  findOne(id) {
    return httpClient.request('get', `users/${id}`)
  },

  generateNewPassword(id) {
    return httpClient.request('post', `users/${id}/password`, id);
  },

  addLogoutTime(data){
    return httpClient.request('post', `users/log-time`, data);
  }

}