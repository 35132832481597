import httpClient from '@/common/http'

export default {
  create(obj) {
    return httpClient.request('post', '/settings/loan_origination/loan_waivers/loan-waiver-policy', obj);
  },

  update(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/loan-waiver-policy`, obj);
  },

  updateWaiverAmount(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/loan-waiver-amount`, obj);
  },

  updateWaiverLevelActive(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/waiver-level-active`, obj);
  },

  updateLoanWaiverNameActive(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/loan-waiver-active`, obj);
  },

  findOne(level) {
    return httpClient.request('get', `/settings/loan_origination/loan_waivers/loan-waiver-policy?level=${level}`)
  },

  findAll() {
    return httpClient.request('get', '/settings/loan_origination/loan_waivers/loan-waiver-policies')
  },

  delete(id) {
    return httpClient.request('delete', `/settings/loan_origination/loan_waivers/loan-waiver-policy/${id}`);
  },

  // insurance waiver
  addInsuranceWaiver(obj) {
    return httpClient.request('post', `/settings/loan_origination/loan_waivers/add-insurance-waiver`, obj);
  },
  editInsuranceWaiver(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/edit-insurance-waiver`, obj);
  },
  getInsuranceWaiver() {
    return httpClient.request('get', `/settings/loan_origination/loan_waivers/get-insurance-waiver`);
  },
  setInsuranceWaiverStatus(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/insurance-level-active`, obj);
  },

  // dsr waiver
  addDSRWaiver(obj) {
    return httpClient.request('post', `/settings/loan_origination/loan_waivers/add-dsr-waiver`, obj);
  },
  editDSRWaiver(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/edit-dsr-waiver`, obj);
  },
  getDSRWaiver() {
    return httpClient.request('get', `/settings/loan_origination/loan_waivers/get-dsr-waiver`);
  },
  setDSRWaiverStatus(obj) {
    return httpClient.request('put', `/settings/loan_origination/loan_waivers/dsr-level-active`, obj);
  }
}