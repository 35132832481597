import httpClient from '@/common/http'

export default {
  create(obj) {
    return httpClient.request('post', 'department/add-department', obj);
  },

  update(obj) {
    return httpClient.request('put', `department/edit-department`, obj);
  },

  findAll() {
    return httpClient.request('get', 'department/get-department')
  },

  delete(data) {
    return httpClient.request('delete', `department/delete-department`,{ID:data});
  }

}