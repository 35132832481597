import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth-store'
import appStateModule from './modules/app-store'
import commonStateModule from './modules/common-store'
import branchStateModule from './modules/branch-store'
import riskFactorStateModule from './modules/risk-factor-store'
import loanTypeModule from './modules/loan-type-store'
import loanProductModule from './modules/loan-product-store'
import approvalSettingsStore from './modules/loan-approval-policy.store'
import waiverSettingsStore from './modules/loan-waiver-policy.store'
import DepartmentStore from './modules/department.store'
import releaseNotesStore from './modules/release-notes-store'
import climateRiskRatingStore from './modules/climate-risk-rating'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    DepartmentStore,
    authState : authModule,
    appState: appStateModule,
    commonState: commonStateModule,
    branchState: branchStateModule,
    riskFactorState: riskFactorStateModule,
    loanTypeState: loanTypeModule,
    loanProductState: loanProductModule,
    approvalSettingsStore,
    waiverSettingsStore,
    releaseNotesState : releaseNotesStore,
    climateRiskRatingStore: climateRiskRatingStore
  }
})
