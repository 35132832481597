import departmentService from '@/services/department-settings-service.js'

export default {
    state: {
        loading: false,
        department_data:[]
    },
    
    getters: {
         getAllDepartment(state) {
            let results = state.department_data;
            return results
        }
    },

    mutations: {
        
        FIND_ALL(state, obj) {
            state.department_data = obj;
        }
    },

    actions: {
        async createDepartment(state, obj) {
            try {
                state.loading = true
                let response = await departmentService.create(obj);
                    return response;
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async updateDepartment(state, obj) {
            try {
                state.loading = true
                let response = await departmentService.update(obj);
                    return response;
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
        async findAllDepartment({state, commit}) {
            
            try {
                state.loading = true
                let response = await departmentService.findAll();
                if(response.length > 0) {
                    commit('FIND_ALL', response)
                    return true
                }
                else {
                    return false
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        },
         async deleteDepartment(state, data) {
            
            try {
                state.loading = true
                let response = await departmentService.delete(data);
                if(response && !response.found) {
                    return response;
                }
            } catch(e) {
                throw e
            } finally {
                state.loading = false
            }
        }
        
    }
}