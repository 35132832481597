import httpClient from '@/common/http'
import storageService from "@/services/storage-service";
import userService from "@/services/user-service";
import moment from "moment";

export default {
  authenticate(email, password) {
    return httpClient.request('post', 'auth', {
      email: email,
      password: password
    });
  },

  getUserPrivileges(ID) {
    return httpClient.request('get', `auth/${ID}/privileges`);
  },

  refreshToken() {
    let refreshToken = storageService.getItem('refresh_token');
    return httpClient.request('get', `auth/refresh`, null, {'x-refresh-token': refreshToken});
  },

  changePassword(id, oldPassword, newPassword) {
    return httpClient.request('put', `users/${id}/password`, {
      oldPassword: oldPassword,
      newPassword: newPassword
    })
  },

  requestPassordChange(email) {
    return httpClient.request('delete', `users/${email}/password`)
  },

  verifyForgottenPassword(token) {
    return httpClient.request('post', `users/forget/password/verification/${token}`)
  },

  async logTime(){
    let data = {
      email: storageService.getItem('user').email,
      last_logout_time: moment().format('YYYY-MM-DD hh:mm:ss A')
    }
    let res = await userService.addLogoutTime(data);
  },

  logout(type) {
    this.logTime()
    storageService.clear()
    window.location.href = window.location.origin + '/login' + (type ? '?redirect=' + type : '')
    
  }
}