
import storageService from "../services/storage-service";

export const AuthorizeDirective =  {
  inserted(el, binding, vnode) {
    let user = storageService.getItem('user')
    let privileges = user.privileges || []
    let show = user.is_admin
    let names = binding.value.split('|')

    if(user.role && Object.keys(user.role).length > 0 && !show) {
      for(let i = 0; i < names.length; i++) {
        show = privileges.filter((el) => el.is_activated && el.privilege_name.toLowerCase() ===  names[i].toLowerCase()).length > 0
        if(show) 
          break
      }
    }
      
    if(!show)
      vnode.elm.remove()
  }
  
}
