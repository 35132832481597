import moment from 'moment';
import storageService from "@/services/storage-service";
import releaseNotesService from "@/services/release-notes-service";

export default {
    state: {
        old_software_updates: [],
        new_software_updates: [],
        software_list: [],
        update_count: 0,
        view_software_updates: false
    },

    mutations: {

        SET_SOFTWARE_LIST(state, software_list) {
            state.software_list = software_list
        },

        SET_UPDATE_COUNT(state, update_count) {
            state.update_count = update_count
        }

    },

    actions: {
        getSoftwareUpdates: async ({ commit, state }) => {
            let user = storageService.getItem("user");
            let result = await releaseNotesService.findLatest();
            state.view_software_updates = storageService.getItem("view_software_updates");

            state.old_software_updates = result[0].filter(el => moment(el.release_notes.date_created).isBetween(user.last_logout_time, storageService.getItem('time_logged_in')));

            state.new_software_updates.forEach(el => state.software_list.push(el.application.app_name));
            state.old_software_updates.forEach(el => state.software_list.push(el.application.app_name));

            commit('SET_SOFTWARE_LIST', state.software_list);
            commit('SET_UPDATE_COUNT', state.software_list.length);

        },

        updateSoftwareList: async ({ commit, state }, app_name) => {

            state.software_list = state.software_list.filter(el => el != app_name);
            if (state.software_list.length != 0) {
                storageService.setItem("view_software_updates", false);
            } else if (state.software_list.length == 0) {
                storageService.setItem("view_software_updates", true);
            }

            commit('SET_SOFTWARE_LIST', state.software_list);
            commit('SET_UPDATE_COUNT', state.software_list.length);

        }

    }

}