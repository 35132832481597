import CommonService from '@/services/common-service'
import moment from 'moment-timezone'

export default  {
  state : {
    countries: [],
    currencies: [],
    timezones: [],
    audit_log:[]
  },

  getters: {
    getTimezonesAndOffsets(state) {
      let offsetTmz=[];

      for(let i in state.timezones){
        offsetTmz.push({zone: state.timezones[i], format: " (GMT"+moment.tz(state.timezones[i]).format('Z')+") " + state.timezones[i] })
      }
      return offsetTmz
    }
  },

  mutations : {
    SET_COUNTRIES (state, payload){
      state.countries = payload
    },

    SET_CURRENCIES (state, payload){
      state.currencies = payload
    },

    SET_TIMEZONES(state, payload){
      state.timezones = payload
    }
  },

  actions : {
    async getCountries ({commit}) {
      try{
        let response = await CommonService.getCountries()
        if(response){
          let countries = []
          Object.keys(response).forEach((key)=>{
            countries.push(response[key])
          })
          commit('SET_COUNTRIES', countries)
        }
      }catch(e){
       throw e
      }
    },

    async getCurrencies ({commit}) {
      try{
        let response = await CommonService.getCurrencies()
        if(response){
          let currencies = []
          Object.keys(response).forEach((key)=>{
            currencies.push(response[key])
          })
          commit('SET_CURRENCIES', currencies)
        }
      }catch(e){
       throw e
      }
    },

    getTimezones ({commit}) {
      try{
        let timeZones = moment.tz.names()
        commit('SET_TIMEZONES', timeZones)
      }catch(e){
       throw e
      }
    }
  }
}